import { QueryClientProvider } from "react-query";
import React from "react";
import { queryClient } from "../config/queryClient";

const RootElement = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default RootElement;
